import React, { Component } from 'react';

import'./style.css';
import'./responsive.css';

export default class PromoBar extends Component {
  render() {
    return (<div className="promo_wrapper">
      <div className="left_area">
          <h1>Looking for something very special?</h1>
          <p>PLAN YOUR BUDGET AND LET’S GET STARTED!</p>
      </div>
      <div className="right_area">
        <button>Comece agora !</button>
      </div>


    </div>);
  }
}
