import React from 'react';

import './App.css';
import Calculadora from './Pages/Calculadora';
import Menu from './Components/Menu';
import Funcionalidades from './Pages/Funcionalidades';
import PromoBar from './Components/PromoBar';
import Planos from './Pages/Planos';
import Footer from './Components/Footer';

function App() {
  return (
    <div className="App">
      <Menu/>
      <Calculadora/>
      <Funcionalidades/>
      <PromoBar/>
      <Planos/>
      <Footer/>
    </div>
  );
}

export default App;
