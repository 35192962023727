
export const data={
    site:[
        {
            id:1,
            name:'Espaço para o site',
            option:'10 Gb',
            class:"active"
    
        },
        {
            id:2,
            name:'Espaço para backup do site',
            option:'10 Gb'
    
        },
        {
            id:3,
            name:'Windows ou Linux',
            option:'OK',
            class:"active",
    
        },
        {
            id:4,
            name:'Painel Plesk',
            option:'OK',
    
        },
        {
            id:5,
            name:'Certificado SSL Let´s Encrypt',
            option:'OK',
            class:"active",
    
        },
        {
            id:6,
            name:'Web Application Firewall',
            option:'OK',
    
        },
        {
            id:7,
            name:'Memoria PHP',
            option:'256 MB',
            class:"active"
    
        },
        {
            id:8,
            name:'Memoria PHP adicional 512k',
            option:'R$ 20,00 / mês'
    
        },
        {
            id:9,
            name:'Banco de dados My SQL',
            option:'1Gb',
            class:"active"
    
        },
        {
            id:10,
            name:'Banco de dados My SQL (adicional)',
            option:'1R$ 20,00 / mês'
    
        },

        {
            id:11,
            name:'Banco de dados MS-SQL Server',
            option:'R$ 50,00 / mês'
    
        },


        {
            id:12,
            name:'Transferência de Site',
            option:'100 GB'
    
        },
        {
            id:13,
            name:'Discos SSDs',
            option:'OK'
    
        },
    ],

    email:[
        {
            id:14,
            name:'Hospedagem dos e-mails',
            option:'10, 25, 50GB',
            class:"active"
    
        },
        {
            id:15,
            name:'Espaço para Caixa Postal',
            option:'10 Gb'
    
        },
        {
            id:16,
            name:'Webmail c/ aparência Outlook',
            option:'OK',
            class:"active",
    
        },
        {
            id:17,
            name:'Antispam MAV 99% eficiência',
            option:'OK',
    
        },
        {
            id:18,
            name:'Backup/archive dos e-mails',
            option:'OK',
            class:"active",
    
        },
        {
            id:19,
            name:'Agenda, tarefas e contatos compartilhados',
            option:'OK',
    
        },
        {
            id:20,
            name:'Painel de administrador',
            option:'256 MB',
            class:"active"
    
        },
        {
            id:21,
            name:'Painel de controle p/ usuário',
            option:'R$ 20,00 / mês'
    
        },
        {
            id:22,
            name:'Relatório de bloqueio de spams',
            option:'1Gb',
            class:"active"
    
        },
        {
            id:23,
            name:'Liberação da quarenta de spam',
            option:'1R$ 20,00 / mês'
    
        },

        {
            id:24,
            name:'Recibo de entrega de e-mails',
            option:'R$ 50,00 / mês'
    
        },


        {
            id:25,
            name:'Recuperação de e-mails apagados',
            option:'100 GB'
    
        },
        {
            id:26,
            name:'Auditoria nas mensagens de email',
            option:'OK'
    
        },

        {
            id:27,
            name:'Apelidos / contas virtuais',
            option:'OK'
    
        },

        {
            id:28,
            name:'Política de uso por horário',
            option:'OK'
    
        },

        {
            id:29,
            name:'Limitar acesso apenas à rede da empresa?',
            option:'OK'
    
        },
    ]
    }