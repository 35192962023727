import React, { Component } from 'react';

import logo from '../../Assets/img/logo.svg';
// import support from '../../Assets/img/support.svg'
import './style.css'
import './responsive.css'


export default class Menu extends Component {
  render() {
    return <div className="menu_wrapper">
        <div className="logo_container">
            <img src={logo} alt="logo"/> Mail
        </div>

        <div className="call_container">
            {/* <div className="btn_call">
                <div className="circle_icon">
                <img src={support} alt="logo"/>
                </div>
                <p>entre em contato</p>
                </div> */}
        </div>
    </div>;
  }
}
