import React, { Component } from 'react';

import'./style.css';
import'./responsive.css';
import shield from '../../Assets/img/shild.svg';
import graphic from '../../Assets/img/graphic.svg';
import backup from '../../Assets/img/backup.svg';
import hand from '../../Assets/img/hand.svg';
import password from '../../Assets/img/password.svg';
import bill from '../../Assets/img/bill.svg';


export default class Funcionalidades extends Component {
  render() {
    return (<div className="funcionalidades_wrapper">
        <div className="title_wrapper">
            <h1>Funcionalidades</h1>
            <span/>
        </div>

        <div className="funcionalidades_boxes_container">

            <div className="box_wrapper">
                <img src={shield} alt="antispam" width="50px"/>
                <h3>MAV Antispam</h3>
                <p>Garanta 99,9 % de eficácia na proteção contra spams, fraudes e phishing
                (roubo de informações) que chegam em seu serviço de e-mail diariamente. Para isso,
                 são feitas análises de textos do e-mail, black list e assinaturas geradas automaticamente.</p>
            </div>
            <div className="box_wrapper">
            <img src={graphic} alt="antispam" width="50px"/>
                <h3>Auditoria</h3>
                <p>Com o serviço de e-mail da MAV você terá acesso ao registro de todo o tráfego
                     e conteúdo dos seus emails corporativos. Além do melhor monitoramento dos dados,
                      é possível recuperar mensagens já excluídas pelos usuários e fazer a auditoria,
                       ou seja, a documentação dessas informações.</p>
            </div>
            <div className="box_wrapper">
            <img src={backup} alt="antispam" width="50px"/>
                <h3>Backup</h3>
                <p>O e-mail profissional da MAV disponibiliza cópias de segurança de todos as mensagens
                     que circulam na empresa e pode recuperar qualquer mensagem, de maneira imediata.
                      Esses e-mails não podem ser alterados ou apagados e ficam armazenados por até 10 anos.</p>
            </div>

            <div className="box_wrapper">
            <img src={hand} alt="antispam" width="50px"/>
                <h3>Colaboração e Produtividade</h3>
                <p>Aproveite a integração de seu e-mail corporativo com dispositivos móveis.
                     Acesse o webmail de qualquer lugar e compartilhe calendários, contatos
                      e tarefas entre a equipe, tornando mais dinâmico o dia a dia da empresa.</p>
            </div>

            <div className="box_wrapper">
            <img src={password} alt="antispam" width="50px"/>
                <h3>Controle de Acesso</h3>
                <p>Evite acessos inesperados que podem gerar horas extras de seus colaboradores ou aumentar
                     o risco de vazamentos de informações confidenciais. Basta bloquear
                      o acesso aos e-mails fora do horário comercial ou da rede da empresa.
                      </p>
            </div>

            <div className="box_wrapper">
            <img src={bill} alt="antispam" width="50px"/>
                <h3>Recibo de Entrega</h3>
                <p>Identifique problemas no envio de e-mails e evite falhas na comunicação
                    com seu cliente. Disponibilizamos relatórios
                    para que você cheque se um e-mail saiu da sua
                    caixa de saída ou parou no servidor do destinatário.
                       </p>
            </div>

        </div>

    </div>);
  }
}
