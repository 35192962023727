import React, { Component } from 'react';

import'./style.css';

export default class Footer extends Component {
  render() {
    return <div className="footer_wrapper">
        <p>Todos os direitos reservados @2020 – MAV Tecnologia</p>
    </div>;
  }
}
