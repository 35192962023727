import React, { Component } from 'react';

import './style.css';

import './responsive.css';
import {data}from '../../Services/dados';
import $ from 'jquery'

export default class Planos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: 5,
      value2: 10,
      visibility1:'block',
      visibility2:'none'
    };
  }


  plantoggleA = () =>{

    this.setState({
      visibility1:'none',
      visibility2:'block'
    })

  }

  plantoggleB = () =>{

    this.setState({
      visibility1:'block',
      visibility2:'none'
    })

  }
  
componentDidMount(){
  $(document).ready(function(){
    $('.left_area .options_container div').click(function(){
      $('.options_container div').removeClass("active");
      $(this).addClass("active");
  });
  });
}
  render() {
    return (<div className="planos_wrapper">
          <div className="title_wrapper">
            <h1>Planos</h1>
            <span/>
        </div>


        <div className='planos_container'>


          <div className="left_area">
            <div className="options_container">
              <div onClick={()=> this.plantoggleB()} className="active">Hospedagem do Site</div>
              <div onClick={()=> this.plantoggleA()} >Hospedagem dos e-mails</div>
            </div>
          </div>


          <div className="right_area">
            <div className="box_result_plan_wrapper" style={{display:this.state.visibility1}}>
              {data.site.map((item)=>{
                return(
                  <div key={item.id} className='item_wrapper' id={item.class}>
                  <div className="name">{item.name}</div>
                <div className="option">{item.option}</div>
                </div>
                )
              })}
            </div>


            <div className="box_result_plan_wrapper" style={{display:this.state.visibility2}}>
              {data.email.map((item)=>{
                return(
                  <div key={item.id} className='item_wrapper' id={item.class}>
                  <div className="name">{item.name}</div>
                <div className="option">{item.option}</div>
                </div>
                )
              })}
            </div>
          </div>



        </div>
    </div>);
  }
}
