import React, { Component } from 'react';

import'./style.css';
import'./responsive.css';
import InputRange from 'react-input-range';
import Input from 'react-input-mask';
import 'react-input-range/lib/css/index.css';
import hash from '../../Assets/img/HASH.svg';
import close from '../../Assets/img/close.svg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import $ from 'jquery'
import axios from 'axios'


// toast.configure()

export default class Calculadora extends Component {

      constructor(props) {
        super(props);
    
        this.state = {
          value: 0,
          value2: 5,
          caixa:"2 GB",
          backup:"30 DIAS",
          preco:19.90,
          progressWidth:'25%',
          username: '',
          email:'',
          telefone:'',
          cnpj:'',
          company:'',
          mask:"**.***.***/***-**",
          placeholder:"00.000.000/0000-00",
          notify:false,
        };
      }

    username = (event) => {this.setState({username: event.target.value,});}
    
    email = (event) => {this.setState({email:event.target.value,});}

    telefone = (event) => {this.setState({telefone:event.target.value,});}

    cnpj = (event) => {this.setState({cnpj:event.target.value,} );}

    company = (event) => {this.setState({company:event.target.value,});}


      componentDidMount(){
          

        $('.fechar').click(()=>{
            $('.pop_wrapper').fadeOut();
        });

        $('.btn_confirmar_calculadora').click(()=>{
            $('.pop_wrapper').fadeIn();
        })

        $('.form_wrapper form .input_cnpj_cpf').click(function(){
            $('form .input_cnpj_cpf').removeClass("active_type");
            $(this).addClass("active_type");
        });
       
        localStorage.setItem("send", false);

        
        setInterval(()=>{

            switch(this.state.value2) {
                case 5:
                  this.setState({
                    caixa:"1 GB"
                  })
                  break ;
                case 10 :
                    this.setState({
                        caixa:"2 GB"
                      })
                  break;
                  case 15 :
                    this.setState({
                        caixa:"10 GB"
                      })
                  break;
    
                  case 20 :
                    this.setState({
                        caixa:"25 GB"
                      })
                  break;

                  case 25 :
                    this.setState({
                        caixa:"50 GB"
                      })
                  break;
                default:
                  // code block
              }

                // 5 CAIXAS POSTAIS


                if(this.state.value === 0 && this.state.value2 === 5){
                    this.setState({preco:9.90, progressWidth:'3%', backup:"30 DIAS",})
                }
                else if(this.state.value === 0 && this.state.value2 === 10){
                    this.setState({preco:19.90,   progressWidth:'25%', backup:"3 MESES",})
                }
                else if(this.state.value === 0 && this.state.value2 === 15){
                    this.setState({preco:29.90,   progressWidth:'50%', backup:"12 MESES",})
                }
                else if(this.state.value === 0 && this.state.value2 === 20){
                    this.setState({preco:40.00,   progressWidth:'75%', backup:"24 MESES",})
                }
                else if(this.state.value === 0 && this.state.value2 === 25){
                    this.setState({preco:40.00,   progressWidth:'100%', backup:"24 MESES",})
                }

                // 10 CAIXAS POSTAIS

                
                if(this.state.value === 10 && this.state.value2 === 5){
                    this.setState({preco:19.25, progressWidth:'3%', backup:"30 DIAS",})
                }
                else if(this.state.value === 10 && this.state.value2 === 10){
                    this.setState({preco:19.90,   progressWidth:'25%', backup:"3 MESES",})
                }
                else if(this.state.value === 10 && this.state.value2 === 15){
                    this.setState({preco:29.90,   progressWidth:'50%', backup:"12 MESES",})
                }
                else if(this.state.value === 10 && this.state.value2 === 20){
                    this.setState({preco:40.00,   progressWidth:'75%', backup:"24 MESES",})
                }
                else if(this.state.value === 10 && this.state.value2 === 25){
                    this.setState({preco:40.00,   progressWidth:'100%', backup:"24 MESES",})
                }
                

                // 20 CAIXAS POSTAIS

                if(this.state.value === 20 && this.state.value2 === 5){
                    this.setState({preco:24.95, progressWidth:'3%', backup:"30 DIAS",})
                }
                else if(this.state.value === 20 && this.state.value2 === 10){
                    this.setState({preco:49.90, progressWidth:'25%', backup:"30 DIAS"})
                }
                else if(this.state.value === 20 && this.state.value2 === 15){
                    this.setState({preco:69.90,  progressWidth:'50%', backup:"3 MESES",})
                }
                else if(this.state.value === 20 && this.state.value2 === 20){
                    this.setState({preco:99.90,progressWidth:'75%', backup:"12 MESES",})
                }
                else if(this.state.value === 20 && this.state.value2 === 25){
                    this.setState({preco:140.00,progressWidth:'100%', backup:"24 MESES",})
                }

                // 30 CAIXAS POSTAIS


                if(this.state.value === 30 && this.state.value2 === 5){
                    this.setState({preco:29.95, progressWidth:'3%', backup:"30 DIAS",})
                }
                if(this.state.value === 30 && this.state.value2 === 10){
                    this.setState({preco:59.90, progressWidth:'25%', backup:"30 DIAS"})
                }
                else if(this.state.value === 30 && this.state.value2 === 15){
                    this.setState({preco:89.90,  progressWidth:'50%', backup:"3 MESES",})
                }
                else if(this.state.value === 30 && this.state.value2 === 20){
                    this.setState({preco:139.90,progressWidth:'75%', backup:"12 MESES",})
                }
                else if(this.state.value === 30 && this.state.value2 === 25){
                    this.setState({preco:200.00,progressWidth:'100%', backup:"24 MESES",})
                }


                // 40 CAIXAS POSTAIS

                if(this.state.value === 40 && this.state.value2 === 5){
                    this.setState({preco:34.95, progressWidth:'3%', backup:"30 DIAS",})
                }
                else if(this.state.value === 40 && this.state.value2 === 10){
                    this.setState({preco:69.90, progressWidth:'25%', backup:"30 DIAS"})
                }
                else if(this.state.value === 40 && this.state.value2 === 15){
                    this.setState({preco:109.90,  progressWidth:'50%', backup:"3 MESES",})
                }
                else if(this.state.value === 40 && this.state.value2 === 20){
                    this.setState({preco:179.90,progressWidth:'75%', backup:"12 MESES",})
                }
                else if(this.state.value === 40 && this.state.value2 === 25){
                    this.setState({preco:240.00,progressWidth:'100%', backup:"24 MESES",})
                }
            

                
                // 50 CAIXAS POSTAIS

                if(this.state.value === 50 && this.state.value2 === 5){
                    this.setState({preco:39.95, progressWidth:'3%', backup:"30 DIAS",})
                }
                else if(this.state.value === 50 && this.state.value2 === 10){
                    this.setState({preco:79.90, progressWidth:'25%', backup:"30 DIAS"})
                }
                else if(this.state.value === 50 && this.state.value2 === 15){
                    this.setState({preco:129.90,  progressWidth:'50%', backup:"3 MESES",})
                }
                else if(this.state.value === 50 && this.state.value2 === 20){
                    this.setState({preco:219.90,progressWidth:'75%', backup:"12 MESES",})
                }
                else if(this.state.value === 50 && this.state.value2 === 25){
                    this.setState({preco:300.00,progressWidth:'100%', backup:"24 MESES",})
                }


                // 60 CAIXAS POSTAIS

                if(this.state.value === 60 && this.state.value2 === 5){
                    this.setState({preco:47.20, progressWidth:'3%', backup:"30 DIAS",})
                }
                else if(this.state.value === 60 && this.state.value2 === 10){
                    this.setState({preco:94.40, progressWidth:'25%', backup:"30 DIAS"})
                }
                else if(this.state.value === 60 && this.state.value2 === 15){
                    this.setState({preco:149.90,  progressWidth:'50%', backup:"3 MESES",})
                }
                else if(this.state.value === 60 && this.state.value2 === 20){
                    this.setState({preco:259.90,progressWidth:'75%', backup:"12 MESES",})
                }
                else if(this.state.value === 60 && this.state.value2 === 25){
                    this.setState({preco:360.00,progressWidth:'100%', backup:"24 MESES",})
                }



                // 70 CAIXAS POSTAIS


                if(this.state.value === 70 && this.state.value2 === 5){
                    this.setState({preco:54.45, progressWidth:'3%', backup:"30 DIAS",})
                }
               else if(this.state.value === 70 && this.state.value2 === 10){
                    this.setState({preco:108.90, progressWidth:'25%', backup:"30 DIAS"})
                }
                else if(this.state.value === 70 && this.state.value2 === 15){
                    this.setState({preco:169.90, progressWidth:'50%', backup:"3 MESES",})
                }
                else if(this.state.value === 70 && this.state.value2 === 20){
                    this.setState({preco:299.90,progressWidth:'75%', backup:"12 MESES",})
                }
                else if(this.state.value === 70 && this.state.value2 === 25){
                    this.setState({preco:420.00,progressWidth:'100%', backup:"24 MESES",})
                }


                // 80 CAIXAS POSTAIS


                if(this.state.value === 80 && this.state.value2 === 5){
                    this.setState({preco:61.70, progressWidth:'3%', backup:"30 DIAS",})
                }
                else if(this.state.value === 80 && this.state.value2 === 10){
                    this.setState({preco:123.40, progressWidth:'25%', backup:"30 DIAS"})
                }
                else if(this.state.value === 80 && this.state.value2 === 15){
                    this.setState({preco:189.90, progressWidth:'50%', backup:"3 MESES",})
                }
                else if(this.state.value === 80 && this.state.value2 === 20){
                    this.setState({preco:339.90,progressWidth:'75%', backup:"12 MESES",})
                }
                else if(this.state.value === 80 && this.state.value2 === 25){
                    this.setState({preco:480.00,progressWidth:'100%', backup:"24 MESES",})
                }


                // 90 CAIXAS POSTAIS

                if(this.state.value === 90 && this.state.value2 === 5){
                    this.setState({preco:68.95, progressWidth:'3%', backup:"30 DIAS",})
                }
                else if(this.state.value === 90 && this.state.value2 === 10){
                    this.setState({preco:137.90, progressWidth:'25%', backup:"30 DIAS"})
                }
                else if(this.state.value === 90 && this.state.value2 === 15){
                    this.setState({preco:209.90, progressWidth:'50%', backup:"3 MESES",})
                }
                else if(this.state.value === 90 && this.state.value2 === 20){
                    this.setState({preco:379.90,progressWidth:'75%', backup:"12 MESES",})
                }
                else if(this.state.value === 90 && this.state.value2 === 25){
                    this.setState({preco:540.00,progressWidth:'100%', backup:"24 MESES",})
                }

                // 100 CAIXAS POSTAIS

                if(this.state.value === 100 && this.state.value2 === 5){
                    this.setState({preco:76.20, progressWidth:'3%', backup:"30 DIAS",})
                }
                else if(this.state.value === 100 && this.state.value2 === 10){
                    this.setState({preco:152.40, progressWidth:'25%', backup:"30 DIAS"})
                }
                else if(this.state.value === 100 && this.state.value2 === 15){
                    this.setState({preco:229.90,  progressWidth:'50%', backup:"3 MESES",})
                }
                else if(this.state.value === 100 && this.state.value2 === 20){
                    this.setState({preco:419.90,progressWidth:'75%', backup:"12 MESES",})
                }
                else if(this.state.value === 100 && this.state.value2 === 25){
                    this.setState({preco:600.00,progressWidth:'100%', backup:"24 MESES",})
                }




                // 110 CAIXAS POSTAIS

                if(this.state.value === 110 && this.state.value2 === 5){
                    this.setState({preco:83.45, progressWidth:'3%', backup:"30 DIAS",})
                }
                else if(this.state.value === 110 && this.state.value2 === 10){
                    this.setState({preco:166.90, progressWidth:'25%', backup:"30 DIAS"})
                }
                else if(this.state.value === 110 && this.state.value2 === 15){
                    this.setState({preco:249.90,  progressWidth:'50%', backup:"3 MESES",})
                }
                else if(this.state.value === 110 && this.state.value2 === 20){
                    this.setState({preco:459.90,progressWidth:'75%', backup:"12 MESES",})
                }
                else if(this.state.value === 110 && this.state.value2 === 25){
                    this.setState({preco:660.00,progressWidth:'100%', backup:"24 MESES",})
                }


                // 120 CAIXAS POSTAIS

                if(this.state.value === 120 && this.state.value2 === 5){
                    this.setState({preco:90.70, progressWidth:'3%', backup:"30 DIAS",})
                }
                else if(this.state.value === 120 && this.state.value2 === 10){
                    this.setState({preco:181.40, progressWidth:'25%', backup:"30 DIAS"})
                }
                else if(this.state.value === 120 && this.state.value2 === 15){
                    this.setState({preco:269.90, progressWidth:'50%', backup:"3 MESES",})
                }
                else if(this.state.value === 120 && this.state.value2 === 20){
                    this.setState({preco:499.90,progressWidth:'75%', backup:"12 MESES",})
                }
                else if(this.state.value === 120 && this.state.value2 === 25){
                    this.setState({preco:720.00,progressWidth:'100%', backup:"24 MESES",})
                }


                // 130 CAIXAS POSTAIS

                if(this.state.value === 130 && this.state.value2 === 5){
                    this.setState({preco:97.95, progressWidth:'3%', backup:"30 DIAS",})
                }
               else if(this.state.value === 130 && this.state.value2 === 10){
                    this.setState({preco:195.90, progressWidth:'25%', backup:"30 DIAS"})
                }
                else if(this.state.value === 130 && this.state.value2 === 15){
                    this.setState({preco:289.90, progressWidth:'50%', backup:"3 MESES",})
                }
                else if(this.state.value === 130 && this.state.value2 === 20){
                    this.setState({preco:539.90,progressWidth:'75%', backup:"12 MESES",})
                }
                else if(this.state.value === 130 && this.state.value2 === 25){
                    this.setState({preco:780.00,progressWidth:'100%', backup:"24 MESES",})
                }

                // 140 CAIXAS POSTAIS


                if(this.state.value === 140 && this.state.value2 === 5){
                    this.setState({preco:105.20, progressWidth:'3%', backup:"30 DIAS",})
                }
                else if(this.state.value === 140 && this.state.value2 === 10){
                    this.setState({preco:210.40, progressWidth:'25%', backup:"30 DIAS"})
                }
                else if(this.state.value === 140 && this.state.value2 === 15){
                    this.setState({preco:309.90, progressWidth:'50%', backup:"3 MESES",})
                }
                else if(this.state.value === 140 && this.state.value2 === 20){
                    this.setState({preco:579.90,progressWidth:'75%', backup:"12 MESES",})
                }
                else if(this.state.value === 140 && this.state.value2 === 25){
                    this.setState({preco:840.00,progressWidth:'100%', backup:"24 MESES",})
                }


                // 150 CAIXAS POSTAIS


                if(this.state.value === 150 && this.state.value2 === 5){
                    this.setState({preco:112.45, progressWidth:'3%', backup:"30 DIAS",})
                }
                else if(this.state.value === 150 && this.state.value2 === 10){
                    this.setState({preco:224.90, progressWidth:'25%', backup:"30 DIAS"})
                }
                else if(this.state.value === 150 && this.state.value2 === 15){
                    this.setState({preco:329.90, progressWidth:'50%', backup:"3 MESES",})
                }
                else if(this.state.value === 150 && this.state.value2 === 20){
                    this.setState({preco:619.90,progressWidth:'75%', backup:"12 MESES",})
                }
                else if(this.state.value === 150 && this.state.value2 === 25){
                    this.setState({preco:900.00,progressWidth:'100%', backup:"24 MESES",})
                }
            

                
                // 160 CAIXAS POSTAIS

                if(this.state.value === 160 && this.state.value2 === 5){
                    this.setState({preco:119.70, progressWidth:'3%', backup:"30 DIAS",})
                }
                else if(this.state.value === 160 && this.state.value2 === 10){
                    this.setState({preco:239.40, progressWidth:'25%', backup:"30 DIAS"})
                }
                else if(this.state.value === 160 && this.state.value2 === 15){
                    this.setState({preco:349.90, progressWidth:'50%', backup:"3 MESES",})
                }
                else if(this.state.value === 160 && this.state.value2 === 20){
                    this.setState({preco:659.90,progressWidth:'75%', backup:"12 MESES",})
                }
                else if(this.state.value === 160 && this.state.value2 === 25){
                    this.setState({preco:960.00,progressWidth:'100%', backup:"24 MESES",})
                }


                // 170 CAIXAS POSTAIS

                if(this.state.value === 170 && this.state.value2 === 5){
                    this.setState({preco:126.95, progressWidth:'3%', backup:"30 DIAS",})
                }
                else if(this.state.value === 170 && this.state.value2 === 10){
                    this.setState({preco:253.90, progressWidth:'25%', backup:"30 DIAS"})
                }
                else if(this.state.value === 170 && this.state.value2 === 15){
                    this.setState({preco:369.90, progressWidth:'50%', backup:"3 MESES",})
                }
                else if(this.state.value === 170 && this.state.value2 === 20){
                    this.setState({preco:699.90,progressWidth:'75%', backup:"12 MESES",})
                }
                else if(this.state.value === 170 && this.state.value2 === 25){
                    this.setState({preco:1020.00,progressWidth:'100%', backup:"24 MESES",})
                }


                // 180 CAIXAS POSTAIS

                if(this.state.value === 180 && this.state.value2 === 5){
                    this.setState({preco:134.20, progressWidth:'3%', backup:"30 DIAS",})
                }
                else if(this.state.value === 180 && this.state.value2 === 10){
                    this.setState({preco:268.40, progressWidth:'25%', backup:"30 DIAS"})
                }
                else if(this.state.value === 180 && this.state.value2 === 15){
                    this.setState({preco:389.90, progressWidth:'50%', backup:"3 MESES",})
                }
                else if(this.state.value === 180 && this.state.value2 === 20){
                    this.setState({preco:739.90,progressWidth:'75%', backup:"12 MESES",})
                }
                else if(this.state.value === 180 && this.state.value2 === 25){
                    this.setState({preco:1080.00,progressWidth:'100%', backup:"24 MESES",})
                }


                // 190 CAIXAS POSTAIS
                if(this.state.value === 190 && this.state.value2 === 5){
                    this.setState({preco:141.45, progressWidth:'3%', backup:"30 DIAS",})
                }
                else if(this.state.value === 190 && this.state.value2 === 10){
                    this.setState({preco:282.90, progressWidth:'25%', backup:"30 DIAS"})
                }
                else if(this.state.value === 190 && this.state.value2 === 15){
                    this.setState({preco:409.90, progressWidth:'50%', backup:"3 MESES",})
                }
                else if(this.state.value === 190 && this.state.value2 === 20){
                    this.setState({preco:779.90,progressWidth:'75%', backup:"12 MESES",})
                }
                else if(this.state.value === 190 && this.state.value2 === 25){
                    this.setState({preco:1140.00,progressWidth:'100%', backup:"24 MESES",})
                }


                // 200 CAIXAS POSTAIS

                if(this.state.value === 200 && this.state.value2 === 5){
                    this.setState({preco:148.70, progressWidth:'3%', backup:"30 DIAS",})
                }
                else if(this.state.value === 200 && this.state.value2 === 10){
                    this.setState({preco:297.40, progressWidth:'25%', backup:"30 DIAS"})
                }
                else if(this.state.value === 200 && this.state.value2 === 15){
                    this.setState({preco:429.90, progressWidth:'50%', backup:"3 MESES",})
                }
                else if(this.state.value === 200 && this.state.value2 === 20){
                    this.setState({preco:819.90,progressWidth:'75%', backup:"12 MESES",})
                }
                else if(this.state.value === 200 && this.state.value2 === 25){
                    this.setState({preco:1200.00,progressWidth:'100%', backup:"24 MESES",})
                }


                // 210 CAIXAS POSTAIS

                if(this.state.value === 210 && this.state.value2 === 5){
                    this.setState({preco:155.95, progressWidth:'3%', backup:"30 DIAS",})
                }
                else if(this.state.value === 210 && this.state.value2 === 10){
                    this.setState({preco:311.90, progressWidth:'25%', backup:"30 DIAS"})
                }
                else if(this.state.value === 210 && this.state.value2 === 15){
                    this.setState({preco:449.90, progressWidth:'50%', backup:"3 MESES",})
                }
                else if(this.state.value === 210 && this.state.value2 === 20){
                    this.setState({preco:859.90,progressWidth:'75%', backup:"12 MESES",})
                }
                else if(this.state.value === 210 && this.state.value2 === 25){
                    this.setState({preco:1260.00,progressWidth:'100%', backup:"24 MESES",})
                }

                // 220 CAIXAS POSTAIS

                if(this.state.value === 220 && this.state.value2 === 5){
                    this.setState({preco:163.20, progressWidth:'3%', backup:"30 DIAS",})
                }
                else if(this.state.value === 220 && this.state.value2 === 10){
                    this.setState({preco:326.40, progressWidth:'25%', backup:"30 DIAS"})
                }
                else if(this.state.value === 220 && this.state.value2 === 15){
                    this.setState({preco:469.90, progressWidth:'50%', backup:"3 MESES",})
                }
                else if(this.state.value === 220 && this.state.value2 === 20){
                    this.setState({preco:899.90,progressWidth:'75%', backup:"12 MESES",})
                }
                else if(this.state.value === 220 && this.state.value2 === 25){
                    this.setState({preco:1320.00,progressWidth:'100%', backup:"24 MESES",})
                }




                // 230 CAIXAS POSTAIS

                if(this.state.value === 230 && this.state.value2 === 5){
                    this.setState({preco:170.45, progressWidth:'3%', backup:"30 DIAS",})
                }
                else if(this.state.value === 230 && this.state.value2 === 10){
                    this.setState({preco:340.90, progressWidth:'25%', backup:"30 DIAS"})
                }
                else if(this.state.value === 230 && this.state.value2 === 15){
                    this.setState({preco:489.90, progressWidth:'50%', backup:"3 MESES",})
                }
                else if(this.state.value === 230 && this.state.value2 === 20){
                    this.setState({preco:939.90,progressWidth:'75%', backup:"12 MESES",})
                }
                else if(this.state.value === 230 && this.state.value2 === 25){
                    this.setState({preco:1380.00,progressWidth:'100%', backup:"24 MESES",})
                }


                // 240 CAIXAS POSTAIS

                if(this.state.value === 240 && this.state.value2 === 5){
                    this.setState({preco:177.70, progressWidth:'3%', backup:"30 DIAS",})
                }
                else if(this.state.value === 240 && this.state.value2 === 10){
                    this.setState({preco:355.40, progressWidth:'25%', backup:"30 DIAS"})
                }
                else if(this.state.value === 240 && this.state.value2 === 15){
                    this.setState({preco:509.90, progressWidth:'50%', backup:"3 MESES",})
                }
                else if(this.state.value === 240 && this.state.value2 === 20){
                    this.setState({preco:979.90,progressWidth:'75%', backup:"12 MESES",})
                }
                else if(this.state.value === 240 && this.state.value2 === 25){
                    this.setState({preco:1440.00,progressWidth:'100%', backup:"24 MESES",})
                }


                // 250 CAIXAS POSTAIS

                if(this.state.value === 250 && this.state.value2 === 5){
                    this.setState({preco:184.95, progressWidth:'3%', backup:"30 DIAS",})
                }
                else if(this.state.value === 250 && this.state.value2 === 10){
                    this.setState({preco:369.90, progressWidth:'25%', backup:"30 DIAS"})
                }
                else if(this.state.value === 250 && this.state.value2 === 15){
                    this.setState({preco:529.90, progressWidth:'50%', backup:"3 MESES",})
                }
                else if(this.state.value === 250 && this.state.value2 === 20){
                    this.setState({preco:1019.90,progressWidth:'75%', backup:"12 MESES",})
                }
                else if(this.state.value === 250 && this.state.value2 === 25){
                    this.setState({preco:1500.00,progressWidth:'100%', backup:"24 MESES",})
                }

                // 260 CAIXAS POSTAIS

                if(this.state.value === 260 && this.state.value2 === 5){
                    this.setState({preco:192.20, progressWidth:'3%', backup:"30 DIAS",})
                }
                else if(this.state.value === 260 && this.state.value2 === 10){
                    this.setState({preco:384.40, progressWidth:'25%', backup:"30 DIAS"})
                }
                else if(this.state.value === 260 && this.state.value2 === 15){
                    this.setState({preco:549.90, progressWidth:'50%', backup:"3 MESES",})
                }
                else if(this.state.value === 260 && this.state.value2 === 20){
                    this.setState({preco:1059.90,progressWidth:'75%', backup:"12 MESES",})
                }
                else if(this.state.value === 260 && this.state.value2 === 25){
                    this.setState({preco:1560.00,progressWidth:'100%', backup:"24 MESES",})
                }


                // 270 CAIXAS POSTAIS

                if(this.state.value === 270 && this.state.value2 === 5){
                    this.setState({preco:199.45, progressWidth:'3%', backup:"30 DIAS",})
                }
                else if(this.state.value === 270 && this.state.value2 === 10){
                    this.setState({preco:398.90, progressWidth:'25%', backup:"30 DIAS"})
                }
                else if(this.state.value === 270 && this.state.value2 === 15){
                    this.setState({preco:569.90, progressWidth:'50%', backup:"3 MESES",})
                }
                else if(this.state.value === 270 && this.state.value2 === 20){
                    this.setState({preco:1099.90,progressWidth:'75%', backup:"12 MESES",})
                }
                else if(this.state.value === 270 && this.state.value2 === 25){
                    this.setState({preco:1620.00,progressWidth:'100%', backup:"24 MESES",})
                }



                // 280 CAIXAS POSTAIS

                if(this.state.value === 280 && this.state.value2 === 5){
                    this.setState({preco:206.70, progressWidth:'3%', backup:"30 DIAS",})
                }
                else if(this.state.value === 280 && this.state.value2 === 10){
                    this.setState({preco:413.40, progressWidth:'25%', backup:"30 DIAS"})
                }
                else if(this.state.value === 280 && this.state.value2 === 15){
                    this.setState({preco:589.90, progressWidth:'50%', backup:"3 MESES",})
                }
                else if(this.state.value === 280 && this.state.value2 === 20){
                    this.setState({preco:1139.90,progressWidth:'75%', backup:"12 MESES",})
                }
                else if(this.state.value === 280 && this.state.value2 === 25){
                    this.setState({preco:1680.00,progressWidth:'100%', backup:"24 MESES",})
                }


                // 290 CAIXAS POSTAIS

                if(this.state.value === 290 && this.state.value2 === 5){
                    this.setState({preco:213.95, progressWidth:'3%', backup:"30 DIAS",})
                }
                else if(this.state.value === 290 && this.state.value2 === 10){
                    this.setState({preco:429.90, progressWidth:'25%', backup:"30 DIAS"})
                }
                else if(this.state.value === 290 && this.state.value2 === 15){
                    this.setState({preco:609.90, progressWidth:'50%', backup:"3 MESES",})
                }
                else if(this.state.value === 290 && this.state.value2 === 20){
                    this.setState({preco:1179.90,progressWidth:'75%', backup:"12 MESES",})
                }
                else if(this.state.value === 290 && this.state.value2 === 25){
                    this.setState({preco:1740.00,progressWidth:'100%', backup:"24 MESES",})
                }


                // 300 CAIXAS POSTAIS

                if(this.state.value === 300 && this.state.value2 === 5){
                    this.setState({preco:221.20, progressWidth:'3%', backup:"30 DIAS",})
                }
                if(this.state.value === 300 && this.state.value2 === 10){
                    this.setState({preco:442.40, progressWidth:'25%', backup:"30 DIAS"})
                }
                else if(this.state.value === 300 && this.state.value2 === 15){
                    this.setState({preco:629.90, progressWidth:'50%', backup:"3 MESES",})
                }
                else if(this.state.value === 300 && this.state.value2 === 20){
                    this.setState({preco:1219.90,progressWidth:'75%', backup:"12 MESES",})
                }
                else if(this.state.value === 300 && this.state.value2 === 25){
                    this.setState({preco:1800.00,progressWidth:'100%', backup:"24 MESES",})
                }

        },100);


    
      }

      toggleCnpj = ()=>{
          this.setState({mask:"**.***.***/***-**",placeholder:"00.000.000/0000-00"})
      }

      toggleCpf = ()=>{
        this.setState({mask:"***.***.***-**",placeholder:"000.000.000-00"})
      
    }


    notify = () => {
        toast.success("Solicitação enviada com sucesso !");
    }
      send = (event) =>{
            axios.post('https://simular.mav.com.br/EnvioEmail/envio.php',{
                headers: {'Content-Type':'application/json',
                          'X-Requested-With': 'XMLHttpRequest',
                         },
                            "usuario":"envioemail",
                            "senha":"4ae2977013afd0f3ddec31d67b63e492f7353f96",
                            "destinatario_nome":"Rayna Maia",
                            "destinatario_email":"rayan.maia@mav.com.br",
                            "assunto":"PEDIDO PELO SUBDOMINIO SIMULAR.MAV.COM.BR",
                            "mensagem":  
                            "NOME DO SOLICITANTE: " + this.state.username + "<br/><br/>" + 
                            " E-MAIL: " + this.state.email + "<br/><br/>" +
                            " CPF / CNPJ: " + this.state.cnpj + "<br/><br/>" +
                            " EMPRESA: " + this.state.company + "<br/><br/>" +
                            " TELEFONE: " + this.state.telefone + "<br/><br/>" +
                            " PRECO DO PLANO: " +this.state.preco +"<br/><br/>" +
                            " NUMERO DE CAIXAS POSTAIS: " + this.state.caixa + "<br/><br/>" +
                            " TEMPO DE BACKUP: " + this.state.backup,
              })
              .then(function (response) {
                // console.log(response);

        
              })
              .catch(function (error) {
                // console.log(error);
              });
      }

  render() {

    var res = this.state.value;
    var preco = (this.state.preco).toFixed(2);
    var backup = this.state.backup;
    if(this.state.value === 0 ){
        res = 5;
    }
   

    return <div className="calculadora_wrapper">
        <ToastContainer
            position="top-right"
            autoClose={4000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover
        />
{/* Same as */}
<ToastContainer />
        <div className="pop_wrapper" id="pop_wrapper">
            <div className="fechar">
                {/* <img src={close} width="60px" alt="fechar"/> */}
                x
                </div>
        <div className="pop_send_email">
            <div className="left_area_pop">

                <div className="box_info_pedido_wrapper">
                    <div className="box_pedido">
                        <div className="type_pedido">NUMERO DE CONTAS</div>
                        <h1>{res}</h1>
                    </div>

                    <div className="box_pedido">
                        <div className="type_pedido">CAIXAS POSTAIS</div>
                        <h1>{this.state.caixa}</h1>
                    </div>


                    <div className="box_pedido">
                        <div className="type_pedido">PERIODO DE BACKUP</div>
                        <h1>{backup}</h1>
                    </div>


                    <div className="box_pedido active_price">
                        <div className="type_pedido">PREÇO</div>
                        <h1>{preco}</h1>
                    </div>
                 

                </div>
            </div>



            <div className="right_area_pop">
                <div className="title_pop_wrapper">
                    <h3>REALIZAR PEDIDO</h3>
                </div>

                <div className="form_wrapper">
                    <form onSubmit={this.notify}>
                        <div className="label_form">Nome Completo</div>
                            <Input type="text" onChange={this.username} required placeholder="DIGITE SEU NOME"/>

                        <div className="label_form">EMAIL</div>
                            <Input type="email" onChange={this.email} required placeholder="DIGITE SEU E-MAIL"/>

                        <div className="label_form">Telefone</div>
                            <Input mask="(**) ***** - ****" type="text"onChange={this.telefone} required placeholder="(00) 0000 - 0000"/>
                        <div className="input_cnpj">

                            <div onClick={this.toggleCnpj} className="label_form active_type cnpj input_cnpj_cpf">CNPJ </div> <div onClick={this.toggleCpf} className="label_form cpf input_cnpj_cpf"> CPF</div>

                        </div>
                        

                            <Input className="input_cnpj_value" mask={this.state.mask} type="text" onChange={this.cnpj} required placeholder={this.state.placeholder}/>

                        <div className="label_form">empresa</div>
                            <Input type="text" onChange={this.company} placeholder="DIGITE O NOME DE SUA EMPRESA" />
                        <br/>
                        <button type="submit" className="enviar_email" onClick={this.send}>Confirmar envio</button>
                    </form> 

                  <div className="success_msg">email enviado com sucesso !</div>
                </div>

            
            </div>

         
        </div>

        </div>
     
        <div className='left_area'>
            <div className="text_info_wrapper">
                <div className="text_title">
                    <div className="principal_text">
                        <img src={hash} alt="title"/>
                    </div>

                    <ul>
                        <li><strong>Monitore o tráfego de e-mails</strong> da sua empresa de forma simples</li>
                        <li><strong>Backup de até 10 anos</strong> de todos os e-mails trafegados</li>
                        <li>Suporte Técnico humanizado</li>
                        <li><strong>Relatórios úteis para o seu dia a dia</strong></li>
                        <li>Acesse sua caixa postal via <strong>qualquer dispositivo</strong></li>
                        <li><strong>Recupere mensagens já deletadas</strong> ou perdidas acidentalmente</li>
                        <li><strong>Configure seu site em poucos minutos</strong> com o nosso Plesk</li>
                    </ul>

                </div>
              

                <div className='btn'>Entre em contato</div>
            </div>
            

        </div>
        <div className='right_area'>
            <div className="calc_container_plan">
                <div className="choose_plan_container">
                    <div className="title_default">
                        <h2>Selecione o número de contas <strong>{res}</strong></h2>
                    {/* <p>is simply dummy text </p> */}
                    </div>

                    <InputRange
                        maxValue={300}
                        minValue={0}
                        step={10}
                        value={this.state.value}
                        onChange={value => this.setState({ value })}
                        // onChangeComplete={value => console.log(value)} 
                        />

                    <table className="range_mark_table">
                        <tbody>
                            <tr>
                                <th><span></span></th>
                                <th><span></span></th>
                                <th><span></span></th>
                                <th><span></span></th>
                                <th><span></span></th>
                                <th><span></span></th>
                                <th><span></span></th>
                                <th><span></span></th>
                                <th><span></span></th>
                                <th><span></span></th>
                                <th><span></span></th>
                                <th><span></span></th>
                                <th><span></span></th>
                                <th><span></span></th>
                                <th><span></span></th>
                                <th><span></span></th>
                                <th><span></span></th>
                                <th><span></span></th>
                                <th><span></span></th>
                                <th><span></span></th>
                                <th><span></span></th>
                                <th><span></span></th>
                                <th><span></span></th>
                                <th><span></span></th>
                                <th><span></span></th>
                                <th><span></span></th>
                                <th><span></span></th>
                                <th><span></span></th>
                                <th><span></span></th>
                                <th><span></span></th>
                                <th><span></span></th>
                       
                            </tr>
                        </tbody>
                    </table>
                                    </div>

                <div className="choose_plan_container">
                    <div className="title_default">
                    <h2>Selecione o tamanho das caixas postais <strong>{this.state.caixa}</strong> </h2>
                    {/* <p>is simply dummy text </p> */}
                    </div>

                    <InputRange
                        maxValue={25}
                        minValue={5}
                        step={5}
                        value={this.state.value2}
                        onChange={value2 => this.setState({ value2 })}
                        // onChangeComplete={value2 => console.log(value2)} 
                        />

                    <table className="range_mark_table_b">
                        <tbody>
                            <tr>
                                <th><span><p>1GB</p></span></th>
                                <th><span><p>2GB</p></span></th>
                                <th><span><p>10GB</p></span></th>
                                <th><span><p>25GB</p></span></th>
                                <th><span><p>50GB</p></span></th>
                            
                            </tr>
                        </tbody>
                    </table>
                </div>

                
                <div className="choose_plan_container">
                    <div className="title_default">
                    <h2>Periodo de backup para os planos selecionados<strong>{backup}</strong></h2>
                    {/* <p>is simply dummy text </p> */}
                    </div>

                    <div className="progress_bar_wrapper">
                    <div className="progress-bar blue stripes" style={{width:this.state.progressWidth}}> <span></span> </div>
                    </div>
                        <table className="range_mark_table_c">
                            <tbody>
                            <tr>
                                <th><span></span>30 DIAS</th>
                                <th><span></span>3 MESES</th>
                                <th><span></span>12 MESES</th>
                                <th><span></span>24 MESES</th>
                                <th className="dot"><span></span>.</th>
                            </tr>
                            </tbody>
                        </table>
                </div>

                <div className="result_container">
                    <div className='result_box box_detail'>
                        <div className="type_text">Numero de contas</div>
                            <div className="result_number">{res}</div>
                    </div>
                

                    <div className='result_box box_detail'>
                        <div className="type_text">Caixas postais</div>
                        <div className="result_number">{this.state.caixa}</div>
                    </div>

                    <div className='result_box box_detail'>
                        <div className="type_text">Periodo de backup</div>
                            <div className="result_number">{backup}</div>
                    </div>

                    <div className='result_box active '>
                        <div className="type_text">Preço</div>
                            <div className="result_number preco">{preco}</div>
                    </div>    
                </div>
                <div className="confirm_button_area">
                        <button className="btn_confirmar_calculadora">Confirmar</button>
                        <button  className="btn_outros_planos">Outros planos</button>
                        
                    </div>
            </div>
        </div>
    </div>;
  }
}
